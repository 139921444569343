const rmdWnd = (window as any);

/**
 * Define a "global" property on the window object.
 * Needed for: intl polyfill
 */
rmdWnd.global = window;


/**
 * IE9
 * Need to provide a stub for window.history.replaceState
 */

if (!rmdWnd.history) {
    rmdWnd.history = {};
}

if (!rmdWnd.history.replaceState) {
    rmdWnd.history.replaceState = () => {
        console.log('[IE9 Stub] - history.replaceState() - doing nothing');
    };
}
